import React, { useState, useEffect, forwardRef, useImperativeHandle, useContext } from "react"
import { InputForm } from '../../molecules/Forms/inputForm'

import './image.css'

import { UpdateUserContext } from '../../../pages/Users'
export interface ChildHandles {
    onModal(): void;
  }
const CreateModal = forwardRef<ChildHandles>((props: any, ref) => {
    console.log(props)
  console.log('components/organisms/Footer/DetailModal : 詳細モーダルコンポーネント')

      const [ openModal, setOnModal ] = useState<string>('')
      useImperativeHandle(ref, () => ({
        onModal() {
          setOnModal('-available')
        }
      }))
      const closeModal = () => {
        setOnModal('')
      }
      const updateUserContext = useContext<any>(UpdateUserContext)
      const onUpdateUser = () => {
        closeModal()
        updateUserContext(userInput.mail, userInput.name, userInput.companyName)
      }
      const [ userInput, setUserInput ] = useState({
          mail: '',
          name: '',
          companyName: '',
      })
      const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist()
        const target = e.target
        const name = target.name
        setUserInput({ ...userInput, [name]: target.value })
        console.log(userInput)
      }

    //useEffect(() => {
    //    console.log('estimate')
    //},[])

    return (
        <>
        <div className={`modal-background ${openModal}`}>
<div className="modal">
  <div className="modal-close" onClick={closeModal}></div>
  <p className="modal-head">ユーザー登録</p>
  <div className="modal-main">
      <div>
    <InputForm
        title="メールアドレス"
        name="mail"
        description=""
        value={userInput.mail}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="氏名"
        name="name"
        description=""
        value={userInput.name}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="会社名"
        name="companyName"
        description=""
        value={userInput.companyName}
        onChange={handleInputChange}
    /></div>
  </div>
  <div className="modal-detail">
    <button className="button" onClick={ onUpdateUser }>登録</button>
  </div>
</div>

</div>
        </>
    )
})

export default CreateModal
