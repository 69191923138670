import React, { useRef, useState } from "react"

//import '../styles/globals.css'

import Amplify from 'aws-amplify';

import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);

const Index = () => {

    return (
        <>
      <div className="contents">
        <h1>インデックス</h1>
      </div>
        </>
    );
}

export default Index
