import React from "react"

import '@aws-amplify/ui-react/styles.css'
//import '../../../styles/globals.css'
import { Button } from "@material-ui/core";

interface HeaderProps {
  user?: string
  onClick?: () => void
}

const Header = ({
  user,
  onClick,
}: HeaderProps) => {
  return (
    <>
      <div className="_header">
        <ul>
          <Button>
            <a href="/users"><li>ユーザーリスト</li></a>
          </Button>
          <Button>
            <a href="/estimate/setting"><li>見積り設定</li></a>
          </Button>
        </ul>
        <div className="userList">
          <p className="name">{user}</p>
          <p className="logout" onClick={onClick}>ログアウト</p>
        </div>
        <Button>
            <a href="/user/admins"><li>管理ユーザー</li></a>
          </Button>
      </div>
    </>
  );
}

export default Header
