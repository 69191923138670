import React, { useState, useEffect, forwardRef, useImperativeHandle, useContext } from "react"
import Amplify, { API } from "aws-amplify"

import './modal.css'

import awsconfig from '../../../aws-exports'
Amplify.configure(awsconfig)

export interface ChildHandles {
    onModal(): void
}
const Modal = forwardRef<ChildHandles>((props: any, ref) => {

      const [ openModal, setOnModal ] = useState<string>('')
      useImperativeHandle(ref, () => ({
        onModal() {
          setOnModal('-available')
        }
      }))
      const closeModal = () => {
        setOnModal('')
      }

    return (
      <>
        <div className={`modal-background ${openModal}`}>
          <div className="modal">
            <div className="modal-close" onClick={closeModal}></div>
            <p className="modal-head">{ props?.title }</p>
            <div className="modal-main">
              { props?.slot }
            </div>
          </div>
        </div>
      </>
    )
})
export default Modal
