/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://mtwccyfual.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        },
        {
            "name": "owhEstimateHistory",
            "endpoint": "https://kahcmuz6nd.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        },
        {
            "name": "owhEstimateManages",
            "endpoint": "https://w392g2pmzh.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        },
        {
            "name": "owhEstimatePdfHistory",
            "endpoint": "https://a9qfxv64g2.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        }
    ],
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_f2fQgr9HQ",
    "aws_user_pools_web_client_id": "60a0njglk2tilev8ocbmn6em4a",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
