import React from "react";
import ReactDOM from "react-dom";

type Props = {
  children: React.ReactChild;
};

export const ModalPortal: React.VFC<Props> = ({ children }) => {
  const el = document.getElementById('modal')!
  return ReactDOM.createPortal(children, el)
}