import React, { useRef, createContext } from "react"
import { Route, Routes, BrowserRouter } from "react-router-dom"

import { Auth } from 'aws-amplify'
import {
  AuthState, CognitoUserInterface, onAuthUIStateChange,
} from '@aws-amplify/ui-components'

//import './styles/index.css';
import Header from './components/organisms/Header/Header'
import Index from './pages/Index'
import Users from './pages/Users'
import Setting from './pages/Setting'
import History from './pages/History'

//import './styles/globals.css'

import '@aws-amplify/ui-react/styles.css'

const ADMIN_GROUP_NAME = 'Admins'

export const UserContext = createContext({} as {
  userId?: string
})
interface AuthProps {
  user?: any
  onClick?: () => void
}
const App = ({
  user,
  onClick,
}: AuthProps) => {
  const userId: string = user?.username
  const userName: string = user?.attributes.name

  const isAdmin = (user: CognitoUserInterface | undefined): boolean => {
    const groups = user?.signInUserSession?.accessToken?.payload[
      'cognito:groups'
    ] as Array<string>
    return groups?.includes(ADMIN_GROUP_NAME) ?? false
  }

  const showAdmin = useRef(true)
  if (isAdmin(user)===false) {
    Auth.signOut()
    showAdmin.current = false
  }


  return (
    <>
    {showAdmin.current===true ?
      <BrowserRouter>
        <main>
        <UserContext.Provider value={{ userId: userId }}>
          <Header onClick={onClick} user={userName} />
          <Routes>
            <Route path='/' element={<Index />} />
            <Route path='/users' element={<Users type="Users" />} />
            <Route path='/user/Admins' element={<Users type="Admins" />} />
            <Route path='/estimate/setting' element={<Setting />} />
            <Route path='/estimate/history' element={<History />} />
          </Routes>
          </UserContext.Provider>
        </main>
      </BrowserRouter>
      : '' }
    </>
  );
}

export default App