import React from "react"
import Amplify from "aws-amplify"
import { Authenticator } from '@aws-amplify/ui-react'

//import './styles/index.css';
import App from './App'


//import './styles/globals.css'

import '@aws-amplify/ui-react/styles.css'
import awsconfig from './aws-exports'

Amplify.configure(awsconfig)

const Authentication: React.FC = () => {
  
  const AuthenticatorComponent = {
    Header() {
      return <h2 className="signin-title">見積もりシステム管理画面</h2>
    }
  }
  return (
    <Authenticator components={AuthenticatorComponent} hideSignUp={true}>
      {({ signOut, user }) => (
        <div>
          <App user={user} onClick={signOut} />
        </div>
      )}
    </Authenticator>
  );
}

export default Authentication