import React, { useRef, useState } from 'react';
import './Table.scss'

import { ModalPortal } from '../../../modal'
import Modal, { ChildHandles } from '../../organisms/Users/DetailModal'
import Modal2, { ChildHandles2 } from '../../organisms/Users/ProductModal'
//import { Input } from '../../../atoms/Forms/input'
import BorderColorIcon from '@mui/icons-material/BorderColor';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
interface TableProps {
  thead?: any
  /**
   * Is this the principal call to action on the page?
   */
  tbody?: any
  /**
   * Optional click handler
   */
}

/**
 * Primary UI component for user interaction
 */
export const Table = ({
  thead,
  tbody,
  ...props
}: TableProps) => {

  const onClick = (item:any) => {
    console.log(item)
      const user = {
        mail: item[3].value,
        name: item[2].value,
        companyName: item[1].value,
        uid: item[4].value,
      }
      onClickViewModal(user)
  }
  const onClickProduct = (item:any) => {
    console.log('product')
    console.log(item)
      const user = {
        manage_id: item[1].value,
        manage_name: item[2].value,
        unit_price: item[3].value,
        unit_price_manage: item[4].value,
        palette: item[5].value,
        inventory_rate: item[6].value,
        unit_price_danger: item[7].value,
        unit_price_special: item[8].value,
        unit_price_cold: item[9].value,
        unit_price_freezer: item[10].value,
        pharmacist: item[11].value,
        nestainer: item[12].value,
        unit_price_ac: item[13].value,
        storage_efficiency: item[14].value,
        unit_price_insect: item[15].value,
        insurance_fee_logi: item[16].value,
        unit_price_security: item[17].value,
        receipt: item[18].value,
        issue: item[19].value,
        tag: item[20].value,
        issue_row: item[21].value,
        issue_hon: item[22].value,
        delivery: item[23].value,
        palette_collection: item[24].value,
        transshipment: item[25].value,
        insurance_fee_ship: item[26].value,
        edit_date: item[27].value,
      }

      onClickViewModal2(user)
  }

    const onClickMenu = (item:any) => {
      console.log(item)
        const user = {
          mail: item[3].value,
          name: item[2].value,
          companyName: item[1].value,
          uid: item[4].value,
        }
        onClickViewModal(user)
    }

    const childRef = useRef<ChildHandles>(null)

    const onClickViewModal = (user: any) => {
      console.log(user)
      childRef.current?.onModal(user)
    }
    const childRef2 = useRef<ChildHandles2>(null)
    const onClickViewModal2 = (user: any) => {
      console.log(user)
      childRef2.current?.onModal(user)
    }

  return (
    <>
        <table>
              <thead>
                  <tr>
                  {thead?.map((item: string, idx: number) => (
                        <th key={`head-` + idx}>{item}</th>
                    ))}
                  </tr>
              </thead>
              <tbody>
                {tbody?.map((body:any, idx: number) => (
                <tr key={`body-` + idx}>
                    {body?.map((item: any, i: number) => {
                        if (item.actionType==='onClick') {
                            return (<td key={`item-` + i}><BorderColorIcon onClick={()=>{ onClick(body) }} /></td>)
                        } else if (item.actionType==='onClickMenu') {
                            return (<td key={`item-` + i}><MoreHorizIcon onClick={()=>{ onClickMenu(body) }} /></td>)
                        } else if (item.actionType==='onClickProduct') {
                            return (<td key={`item-` + i}><BorderColorIcon onClick={()=>{ onClickProduct(body) }} /></td>)
                        } else {
                            return (<td key={`item-` + i}>{item.value}</td>)
                        }
                    })}
                </tr>
                ))}
              </tbody>
          </table>
          <ModalPortal>
            <Modal ref={childRef} />
          </ModalPortal>
          <ModalPortal><Modal2 ref={childRef2} /></ModalPortal>
    </>
  )
}