import React, { useState, useEffect, forwardRef, useImperativeHandle, useContext } from "react"
import { InputForm } from '../../molecules/Forms/inputForm'
import Amplify, { API } from "aws-amplify"
import { saveAs } from "file-saver"

import './image.css'

import { UpdateUserContext } from '../../../pages/Users'

import awsconfig from '../../../aws-exports'
Amplify.configure(awsconfig)

export interface ChildHandles {
    onModal(user: any): void;
  }
const DetailModal = forwardRef<ChildHandles>((props: any, ref) => {
    console.log(props)
  console.log('components/organisms/Footer/DetailModal : 詳細モーダルコンポーネント')

      const [ openModal, setOnModal ] = useState<string>('')
      useImperativeHandle(ref, () => ({
        onModal(user: any) {
            console.log('onmodal')
            console.log(user)
            setUserInput(user)
            getHistory(user.uid)
          setOnModal('-available')
        }
      }))
      const closeModal = () => {
        setOnModal('')
      }
      const updateUserContext = useContext<any>(UpdateUserContext)
      const onUpdateUser = () => {
        closeModal()
        updateUserContext(userInput.mail, userInput.name, userInput.companyName)
      }
      const [ userInput, setUserInput ] = useState({
          mail: '',
          name: '',
          companyName: '',
          uid: '',
      })
      const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist()
        const target = e.target
        const name = target.name
        setUserInput({ ...userInput, [name]: target.value })
        console.log(userInput)
      }

      const [ history, setHistory ] = useState<any>()
      const getHistory = async (uid: string) => {
        const api = 'owhEstimateHistory';
        const method = '/items';
        const myInit = {
          headers: {},
          response: true,
          queryStringParameters: {
              uid: uid
          }
        }
        const results = await API.get(api, method, myInit)
        console.log(results.data)
        setHistory(results.data)
      }

      const pdfDownload = async (id:string) => {
        const api = 'owhEstimatePdfHistory';
        const method = '/items';
        const myInit = {
          headers: {},
          response: true,
          queryStringParameters: {id: id}
        }
        const results = await API.get(api, method, myInit)
        const url:any = "data:application/pdf;base64," + results.data
        saveAs(url, 'estimate.pdf')
      }

    //useEffect(() => {
    //    console.log('estimate')
    //},[])

    return (
        <>
        <div className={`modal-background ${openModal}`}>
<div className="modal">
  <div className="modal-close" onClick={closeModal}></div>
  <p className="modal-head">ユーザー詳細</p>
  <div className="modal-main">
      <div>
    <InputForm
        title="メールアドレス"
        name="mail"
        description=""
        value={userInput.mail}
        disabled={true}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="氏名"
        name="name"
        description=""
        value={userInput.name}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="会社名"
        name="companyName"
        description=""
        value={userInput.companyName}
        onChange={handleInputChange}
    /></div>
    <ul>
        {history?.map((h: any, idx: number) => {
            return (<li key={idx}><button onClick={() => pdfDownload(h.history_id)}>{ h.history_date }</button></li>)
        })}
    </ul>
  </div>


</div>

</div>
        </>
    )
})

export default DetailModal
