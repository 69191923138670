import React, { useEffect, useRef, useState, createContext, useCallback } from "react"
import { Auth, API } from 'aws-amplify'
import '../styles/globals.scss'
import { Table } from '../components/molecules/Tables'
import { IlistUsersInGroupUser, IlistUsersInGroup } from '../interfaces'
import Modal3, { ChildHandles3 } from '../components/organisms/Users/ProductCreateModal'

import { ModalPortal } from '../modal'
import Modal, { ChildHandles } from '../components/molecules/Modal/index'

import Amplify from 'aws-amplify';

import awsconfig from '../aws-exports';

export const CreateProductContext = createContext<any>(null)
export const UpdateProductContext = createContext<any>(null)

Amplify.configure(awsconfig);

const LIST_LOOP_LIMIT = 60
interface UserProps {
    type?: string
  }
const Setting = ({
    type
  }: UserProps) => {

    const slot = (
      <>
        <p>Body</p>
      </>
    )

    const childRef = useRef<ChildHandles>(null)
    const props = {
      title: 'タイトル',
      slot: slot,
    }
    const onClickViewModal = () => {
        childRef.current?.onModal()
    }

    const childRef3 = useRef<ChildHandles3>(null)
    const onClickViewModal2 = (user: any) => {
      console.log(user)
      childRef3.current?.onModal(user)
    }
    const onClickProduct = (item:any) => {
      console.log('product')
      console.log(item)
        const user = {
          manage_id: '',
          manage_name: '',
          unit_price: '',
          unit_price_manage: '',
          palette: '',
          inventory_rate: '',
          unit_price_danger: '',
          unit_price_special: '',
          unit_price_cold: '',
          unit_price_freezer: '',
          pharmacist: '',
          nestainer: '',
          unit_price_ac: '',
          storage_efficiency: '',
          unit_price_insect: '',
          insurance_fee_logi: '',
          unit_price_security: '',
          receipt: '',
          issue: '',
          tag: '',
          issue_row: '',
          issue_hon: '',
          delivery: '',
          palette_collection: '',
          transshipment: '',
          insurance_fee_ship: '',
          edit_date: '',
        }
  
        onClickViewModal2(user)
    }
    const onCreateProduct = async (product: any) => {
      const api = 'owhEstimateManages';
        const method = '/items';
        const myInit = {
          headers: {},
          response: true,
          queryStringParameters: product
        }
        const results = await API.post(api, method, myInit)

        console.log(results.data)
        const data = results.data.map((data:any, idx: number) => {
          return [
            { value: '', actionType: 'onClickProduct' },
            { value: idx + 1, actionType: '' },
            { value: data.manage_name, actionType: '' },
            { value: data.unit_price, actionType: '' },
            { value: data.unit_price_manage, actionType: '' },
            { value: data.palette, actionType: '' },
            { value: data.inventry_rate, actionType: '' },
            { value: data.unit_price_danger, actionType: '' },
            { value: data.unit_price_special, actionType: '' },
            { value: data.unit_price_cold, actionType: '' },
            { value: data.unit_price_freezer, actionType: '' },
            { value: data.pharmacist, actionType: '' },
            { value: data.nestainer, actionType: '' },
            { value: data.unit_price_ac, actionType: '' },
            { value: data.storage_efficiency, actionType: '' },
            { value: data.unit_price_insect, actionType: '' },
            { value: data.insurance_fee_logi, actionType: '' },
            { value: data.unit_price_security, actionType: '' },
            { value: data.receipt, actionType: '' },
            { value: data.issue, actionType: '' },
            { value: data.tag, actionType: '' },
            { value: data.issue_row, actionType: '' },
            { value: data.issue_hon, actionType: '' },
            { value: data.delivery, actionType: '' },
            { value: data.palette_collection, actionType: '' },
            { value: data.transshipment, actionType: '' },
            { value: data.insurance_fee_ship, actionType: '' },
            { value: data.edit_date, actionType: '' },
          ]
        })
        setProducts(data)
    }

    const onUpdateProduct = async (product: any) => {
      const api = 'owhEstimateManages';
        const method = '/items';
        const myInit = {
          headers: {},
          response: true,
          queryStringParameters: product
        }
        const results = await API.put(api, method, myInit)

        console.log(results.data)
        const data = results.data.map((data:any, idx: number) => {
          return [
            { value: '', actionType: 'onClickProduct' },
            { value: idx + 1, actionType: '' },
            { value: data.manage_name, actionType: '' },
            { value: data.unit_price, actionType: '' },
            { value: data.unit_price_manage, actionType: '' },
            { value: data.palette, actionType: '' },
            { value: data.inventry_rate, actionType: '' },
            { value: data.unit_price_danger, actionType: '' },
            { value: data.unit_price_special, actionType: '' },
            { value: data.unit_price_cold, actionType: '' },
            { value: data.unit_price_freezer, actionType: '' },
            { value: data.pharmacist, actionType: '' },
            { value: data.nestainer, actionType: '' },
            { value: data.unit_price_ac, actionType: '' },
            { value: data.storage_efficiency, actionType: '' },
            { value: data.unit_price_insect, actionType: '' },
            { value: data.insurance_fee_logi, actionType: '' },
            { value: data.unit_price_security, actionType: '' },
            { value: data.receipt, actionType: '' },
            { value: data.issue, actionType: '' },
            { value: data.tag, actionType: '' },
            { value: data.issue_row, actionType: '' },
            { value: data.issue_hon, actionType: '' },
            { value: data.delivery, actionType: '' },
            { value: data.palette_collection, actionType: '' },
            { value: data.transshipment, actionType: '' },
            { value: data.insurance_fee_ship, actionType: '' },
            { value: data.edit_date, actionType: '' },
          ]
        })
        setProducts(data)
  }

    const thead = useRef([
      '',
      'マネージID',
      'マネージ名',
      '一般倉庫坪単価',
      '一般倉庫管理単価',
      'パレット積数',
      '在庫率',
      '危険物倉庫坪単価',
      '特薬品倉庫坪単価',
      '保冷庫坪単価',
      '冷凍庫坪単価',
      '管理薬剤師単価',
      'ネステナレンタル単価',
      '空調設備/坪単価',
      '保管効率',
      '防虫防鼠単価',
      '保険料',
      'セキュリティ管理単価',
      '入庫単価/ケース',
      '出庫単価/ケース',
      '荷札貼付',
      '出庫単価/行',
      '出庫単価/本',
      '配送単価/ケース',
      'パレット回収',
      '積替え',
      '保険料',
      '編集日',
    ])
    const [ products, setProducts ] = useState()

    useEffect(() => {
      (async () => {
        console.log('GET DATA -- API: owhEstimate/items - データ取得API')
        const api = 'owhEstimateManages';
        const method = '/items';
        const myInit = {
          headers: {},
          response: true,
          queryStringParameters: {
          }
        }
        const results = await API.get(api, method, myInit)
        console.log(results.data)
        const data = results.data.map((data:any, idx: number) => {
          return [
            { value: '', actionType: 'onClickProduct' },
            { value: data.manage_id, actionType: '' },
            { value: data.manage_name, actionType: '' },
            { value: data.unit_price, actionType: '' },
            { value: data.unit_price_manage, actionType: '' },
            { value: data.palette, actionType: '' },
            { value: data.inventory_rate, actionType: '' },
            { value: data.unit_price_danger, actionType: '' },
            { value: data.unit_price_special, actionType: '' },
            { value: data.unit_price_cold, actionType: '' },
            { value: data.unit_price_freezer, actionType: '' },
            { value: data.pharmacist, actionType: '' },
            { value: data.nestainer, actionType: '' },
            { value: data.unit_price_ac, actionType: '' },
            { value: data.storage_efficiency, actionType: '' },
            { value: data.unit_price_insect, actionType: '' },
            { value: data.insurance_fee_logi, actionType: '' },
            { value: data.unit_price_security, actionType: '' },
            { value: data.receipt, actionType: '' },
            { value: data.issue, actionType: '' },
            { value: data.tag, actionType: '' },
            { value: data.issue_row, actionType: '' },
            { value: data.issue_hon, actionType: '' },
            { value: data.delivery, actionType: '' },
            { value: data.palette_collection, actionType: '' },
            { value: data.transshipment, actionType: '' },
            { value: data.insurance_fee_ship, actionType: '' },
            { value: data.edit_date, actionType: '' },
          ]
        })
        setProducts(data)
      })()
    }, [setProducts])

    return (
      <>
      <CreateProductContext.Provider value={onCreateProduct}>
      <UpdateProductContext.Provider value={onUpdateProduct}>
        <div className="pageHead">
          <button className="button" onClick={onClickProduct}>追加</button>
        </div>       
        <div className="results">
          <div className="product-list">
            <Table
              thead={thead.current}
              tbody={products}
            />
          </div>
        </div>
        
        <ModalPortal>
          <Modal {...props} ref={childRef}></Modal>
        </ModalPortal>
        <ModalPortal><Modal3 ref={childRef3} /></ModalPortal>
        </UpdateProductContext.Provider>
        </CreateProductContext.Provider>
      </>
    );
}
export default Setting
