import React, { useState, useEffect, forwardRef, useImperativeHandle, useContext } from "react"
import { InputForm } from '../../molecules/Forms/inputForm'
import Amplify, { API } from "aws-amplify"
import { saveAs } from "file-saver"

import './image.css'

import { CreateProductContext } from '../../../pages/Setting'

import awsconfig from '../../../aws-exports'
Amplify.configure(awsconfig)

export interface ChildHandles3 {
    onModal(user: any): void;
  }
const ProductCreateModal = forwardRef<ChildHandles3>((props: any, ref) => {
    console.log(props)
  console.log('components/organisms/Footer/DetailModal2 : 詳細モーダルコンポーネント')

      const [ openModal, setOnModal ] = useState<string>('')
      useImperativeHandle(ref, () => ({
        onModal(user: any) {
            console.log('onmodal')
            console.log(user)
            setUserInput(user)
          setOnModal('-available')
        }
      }))
      const closeModal = () => {
        setOnModal('')
      }
      const createProductContext = useContext<any>(CreateProductContext)
      const onCreateProduct = () => {
        closeModal()
        createProductContext(userInput)
      }
      const [ userInput, setUserInput ] = useState({
        manage_id: '',
        manage_name: '',
        unit_price: '',
        unit_price_manage: '',
        palette: '',
        inventory_rate: '',
        unit_price_danger: '',
        unit_price_special: '',
        unit_price_cold: '',
        unit_price_freezer: '',
        pharmacist: '',
        nestainer: '',
        unit_price_ac: '',
        storage_efficiency: '',
        unit_price_insect: '',
        insurance_fee_logi: '',
        unit_price_security: '',
        receipt: '',
        issue: '',
        tag: '',
        issue_row: '',
        issue_hon: '',
        delivery: '',
        palette_collection: '',
        transshipment: '',
        insurance_fee_ship: '',
        edit_date: '',
      })
      const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist()
        const target = e.target
        const name = target.name
        setUserInput({ ...userInput, [name]: target.value })
        console.log(userInput)
      }


    //useEffect(() => {
    //    console.log('estimate')
    //},[])

    return (
        <>
        <div className={`modal-background ${openModal}`}>
<div className="modal">
  <div className="modal-close" onClick={closeModal}></div>
  <p className="modal-head">商品登録</p>
  <div className="modal-main">
      <div>
    <InputForm
        title="マネージ名"
        name="manage_name"
        description=""
        value={userInput.manage_name}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="一般倉庫坪単価"
        name="unit_price"
        description=""
        value={userInput.unit_price}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="一般倉庫管理単価"
        name="unit_price_manage"
        description=""
        value={userInput.unit_price_manage}
        onChange={handleInputChange}
    /></div>

    <div>
    <InputForm
        title="パレット積数"
        name="palette"
        description=""
        value={userInput.palette}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="在庫率"
        name="inventory_rate"
        description=""
        value={userInput.inventory_rate}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="危険物倉庫坪単価"
        name="unit_price_danger"
        description=""
        value={userInput.unit_price_danger}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="特薬品倉庫坪単価"
        name="unit_price_special"
        description=""
        value={userInput.unit_price_special}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="保冷庫坪単価"
        name="unit_price_cold"
        description=""
        value={userInput.unit_price_cold}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="冷凍庫坪単価"
        name="unit_price_freezer"
        description=""
        value={userInput.unit_price_freezer}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="管理薬剤師単価"
        name="pharmacist"
        description=""
        value={userInput.pharmacist}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="ネステナレンタル単価"
        name="nestainer"
        description=""
        value={userInput.nestainer}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="空調設備/坪単価"
        name="unit_price_ac"
        description=""
        value={userInput.unit_price_ac}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="保管効率"
        name="storage_efficiency"
        description=""
        value={userInput.storage_efficiency}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="防虫防鼠単価"
        name="unit_price_insect"
        description=""
        value={userInput.unit_price_insect}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="保険料"
        name="insurance_fee_logi"
        description=""
        value={userInput.insurance_fee_logi}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="セキュリティ管理単価"
        name="unit_price_security"
        description=""
        value={userInput.unit_price_security}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="入庫単価/ケース"
        name="receipt"
        description=""
        value={userInput.receipt}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="出庫単価/ケース"
        name="issue"
        description=""
        value={userInput.issue}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="荷札貼付"
        name="tag"
        description=""
        value={userInput.tag}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="出庫単価/行"
        name="issue_row"
        description=""
        value={userInput.issue_row}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="出庫単価/本"
        name="issue_hon"
        description=""
        value={userInput.issue_hon}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="配送単価/ケース"
        name="delivery"
        description=""
        value={userInput.delivery}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="パレット回収"
        name="palette_collection"
        description=""
        value={userInput.palette_collection}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="積替え"
        name="transshipment"
        description=""
        value={userInput.transshipment}
        onChange={handleInputChange}
    /></div>
    <div>
    <InputForm
        title="保険料"
        name="insurance_fee_ship"
        description=""
        value={userInput.insurance_fee_ship}
        onChange={handleInputChange}
    /></div>


    <div className="modal-detail">
    <button className="button" onClick={ onCreateProduct }>登録</button>
  </div>
  </div>


</div>

</div>
        </>
    )
})

export default ProductCreateModal
