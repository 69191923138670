import React from 'react';
import './input.scss';

interface InputProps {
  /**
   * Button contents
   */
  placeholder?: string
  /**
   * Optional click handler
   */
  name?: string

  value?: string

  disabled?: boolean

  type?: 'text' | 'password' | 'number'

  onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void | undefined)
}

/**
 * Primary UI component for user interaction
 */
export const Input = ({
  placeholder,
  type = 'text',
  onChange,
  ...props
}: InputProps) => {
  return (
    <div className="input-wrap">
      <input
        type="text"
        className={['input-text'].join(' ')}
        placeholder={placeholder}
        onChange={e => onChange(e)}
        {...props}
      />
    </div>
  )
}