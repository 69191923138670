import React, { useEffect, useRef, useState, createContext, useCallback } from "react"
import { Auth, API } from 'aws-amplify'
import '../styles/globals.scss'
import { Table } from '../components/molecules/Tables'
import { IlistUsersInGroupUser, IlistUsersInGroup } from '../interfaces'

import { ModalPortal } from '../modal'
import Modal, { ChildHandles } from '../components/organisms/Users/CreateModal'

import Amplify from 'aws-amplify';

import awsconfig from '../aws-exports';

export const UpdateUserContext = createContext<any>(null)

Amplify.configure(awsconfig);

const LIST_LOOP_LIMIT = 60
interface UserProps {
    type?: string
  }
const Users = ({
    type
  }: UserProps) => {

    const [ users, setUsers ] = useState<String[]>([])

    const childRef = useRef<ChildHandles>(null)

    const onClickViewModal = () => {
        childRef.current?.onModal()
    }

    const tableActionRef = useRef<ChildHandles>(null)
    const onClickColumnAction = () => {
        tableActionRef.current?.onModal()
        //alert()
    }

    const createUser = async (username: string, userAttributes: any) => {
        const apiName = 'AdminQueries'
        const path = '/createUser'
        const params = {
            headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession())
                .getAccessToken()
                .getJwtToken()}`,
            },
            body: {
            username,
            userAttributes,
            },
        };
        return await API.post(apiName, path, params);
    }


    // const updateUser = async (username: string, userAttributes: any) => {
    //     const apiName = 'AdminQueries';
    //     const path = '/updateUser';
    //     const params = {
    //         headers: {
    //         'Content-Type': 'application/json',
    //         Authorization: `${(await Auth.currentSession())
    //             .getAccessToken()
    //             .getJwtToken()}`,
    //         },
    //         body: {
    //         username,
    //         userAttributes,
    //         },
    //     };
    //     return await API.post(apiName, path, params);
    // }

    const addUserToGroup = async (username: string, groupname: any) => {
        const apiName = 'AdminQueries'
        const path = '/addUserToGroup'
        const params = {
            headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession())
                .getAccessToken()
                .getJwtToken()}`,
            },
            body: {
            username,
            groupname,
            },
        };
        return await API.post(apiName, path, params);
    }

    const thead = useRef([
        'No.','会社名','氏名','メールアドレス',''
    ])

    
    const onUpdateUser = async (email: string, name: string, companyName: string) => {
        const username = email
        const attrs = [
            { Name: 'email', Value: email },
            { Name: 'name', Value: name },
            { Name: 'email_verified', Value: 'false' },
            { Name: 'custom:company', Value: companyName },
          ]
          
          const result = await createUser(username, attrs)
          console.log(result.User.Username)

          const resultGroup = await addUserToGroup(result.User.Username, type)
          console.log(resultGroup)
          const userList = await getUserList()
          setUsers([...userList])
    }

    //const getUserList = async () => {
    const getUserList = useCallback( async() => {
        let api = 'AdminQueries';
            let path = '/listUsersInGroup';
            const myInit = {
                queryStringParameters: {
                groupname: type,
                limit: LIST_LOOP_LIMIT,
                token: null,
                },
                headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`,
                },
            }
            const results: IlistUsersInGroup = await API.get(api, path, myInit)
            let userList:any = []
            console.log(results.Users)
            const nextToken: String = results.NextToken
            results.Users?.forEach((r:IlistUsersInGroupUser, idx:number) => {
                userList.push([
                    {value: (idx + 1)},
                    {value: r.Attributes[4].Value, actionType: ''},
                    {value: r.Attributes[2].Value, actionType: ''},
                    {value: r.Attributes[3].Value, actionType: ''},
                    {value: r.Attributes[0].Value, actionType: 'onClick'}
                ])
            })
            return userList
    }, [type])

    const user = {
        user: 'email',
        name: 'name',
        companyName: 'companyName'
    }
    useEffect(() => {
        async function fetchListAsync() {
            const userList = await getUserList()
            setUsers([...userList])
        }
        fetchListAsync()
    },[setUsers, getUserList]);

    return (
        <>
            <div className="pageHead"><button className="button" onClick={onClickViewModal}>作成</button></div>
            
                <div className="results">
                <Table
                    thead={thead.current}
                    tbody={users}
                />
                </div>
            <UpdateUserContext.Provider value={onUpdateUser}>
                <ModalPortal>
                    <Modal {...user} ref={childRef} />
                </ModalPortal>
            </UpdateUserContext.Provider>


          
        </>
    );
}
export default Users
